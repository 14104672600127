<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="amber darken-3"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="text-unset"
                >
                    {{ $t('change-email')}}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ $t('change-email-address')}}
                </v-card-title>

                <v-card-text>
                    <b-form>
                        <b-row>
                            <b-col md="12" >
                                <b-form-group
                                    :label="$t('enter-current-password')"
                                >
                                    <b-form-input id="password-0" type="password" name="password-0" :placeholder="$t('password')" v-model="form.password" required />
                                </b-form-group>
                            </b-col>

                            <b-col md="12" >
                                <b-form-group
                                    :label="$t('enter-email-address')"
                                >
                                    <b-form-input id="email" type="email" name="email" :placeholder="$t('email')" v-model="form.email" required />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="danger"
                        text
                        @click="dialog = false"
                        class="text-unset"
                        small
                    >
                        {{ $t('close')}}
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="resetPassword"
                        class="text-unset"
                        small
                    >
                        {{ $t('change')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import gql from "graphql-tag";
import {mapGetters} from "vuex";
import router from "@/router";

export default {
    name: "ChangeEmailModal",
    data() {
        return {
            form: {
                password: '',
                email: ''
            },
            dialog: false,
            error: null
        }
    },
    computed: {
        ...mapGetters({
            username: 'user/username'
        })
    },
    methods: {
        resetPassword() {
            this.$apollo.mutate({
                mutation: gql`mutation($input:SendConfirmationEmailInput!){sendConfirmationEmail(input:$input){error,user{id,username,email}}}`,
                variables: {
                    input: {
                        username: this.username,
                        password: this.form.password,
                        email: this.form.email
                    }
                }
            }).then(async function (resp) {
                if (!resp.data.sendConfirmationEmail.error) {
                    await router.go(0)
                }
                else {
                    this.error = resp.data.error
                }
            })
        }
    }
}
</script>

<style scoped>

</style>