<template>
    <div v-if="page">
        <h2>{{ $t('profile-title')}}</h2>
        <div class="row d-sm-none">
            <div class="widget-content-left">

                <b-dropdown toggle-class="p-0 mr-1" variant="link" no-caret right>
                  <span slot="button-content">
                    <div class="icon-wrapper icon-wrapper-alt">
                      <div class="language-icon">
                        <country-flag :country="currentFlag" class="opacity-8" />
                      </div>
                    </div>
                  </span>
                    <div class="dropdown-menu-header">
                        <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                            <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
                            <div class="menu-header-content text-center text-white">
                                <h6 class="menu-header-subtitle mt-0"> Choose Language </h6>
                            </div>
                        </div>
                    </div>
                    <div class="flex">

                        <button type="button" tabindex="0" class="mx-1" v-for="lang in languages" :key="lang" @click="selectLang(lang)" >
                            <country-flag :country="getFlag(lang)" class="mr-3 opacity-8 flag-align" />
                        </button>
                    </div>
                </b-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-8">
                <profile-box class="w-100" :user.sync="page.viewer" @onUpdate="onUpdate" />
            </div>
            <div class="col-lg-4 d-block">
                <affiliate class="mb-3" :invite-id="page.viewer.inviteId" :leg.sync="page.viewer.inviteLeg" />
            </div>
        </div>
    </div>

</template>

<script>
    import ProfileBox from "../components/Profile/ProfileBox";
    import gql from "graphql-tag";
    import Affiliate from "@/components/Profile/Affiliate";
    import CountryFlag from "vue-country-flag";
    export default {
        name: "Dashboard",
        components: {
            Affiliate,
            ProfileBox,
            CountryFlag
        },
        data() {
            return {
                subheading:
                    "My hotelium profile",
                icon: "pe-7s-user icon-gradient bg-strong-bliss",
                page: null,
                languages: [
                    'en',
                    'hr',
                    'ba',
                    'rs',
                    'gr',
                    'me',
                ],
                language: 'en'
            }
        },
        computed: {

            currentFlag() {
                if (this.language === 'en') return "GB"
                return this.language.toUpperCase()
            },
        },
        mounted() {
            if (!localStorage.getItem("lang")) {
                localStorage.setItem("lang", "en")
            }
            this.language = localStorage.getItem("lang")
        },
        methods: {
            onUpdate() {
                this.$apollo.queries.page.refetch()
            },
            getFlag(code) {
                if (code === 'en') return "GB"
                return code.toUpperCase()
            },
            selectLang(code) {
                if (this.languages.includes(code)) {
                    this.language = code
                    localStorage.setItem("lang", code)
                    window.location.reload()
                }
            }
        },
        apollo: {
            page: {
                query: gql`query page {
                    viewerIsAdmin,
                    viewer{id,username,firstName,lastName,createdAt,email,dateOfBirth,country,gender,hasKYC,heirContact,inviteLeg,inviteId,totals{rank,tokens,maxRank},kycStatus{status,message},sponsor{id,username,email}}
                }`,
                update: data => data,
                result({data}) {
                    if (data) {
                        this.$store.commit('user/updateAvailable', {
                            tokens: data.viewer.totals.tokens
                        })
                        if (data.viewerIsAdmin) {
                            this.$store.commit('user/setAdmin')
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>