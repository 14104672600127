<template>
    <div>
        <div class="card-shadow-primary card-border mb-3 card">
                <div class="dropdown-menu-header">
                    <div class="dropdown-menu-header-inner bg-primary">
                        <div class="menu-header-content">
                            <div class="avatar-icon-wrapper" v-if="user.totals.maxRank > 4">
                                <div class="avatar-icon avatar-icon-profile rounded btn-hover-shine">
                                    <img :src="getRankImg(user.totals.maxRank)" alt="Rank profile.rank" />
                                </div>
                            </div>
                            <div>
                                <h5 class="menu-header-title">{{ user.firstName }} {{ user.lastName }}</h5>
                            </div>
                            <div class="menu-header-btn-pane d-flex w-100 flex-wrap wrap">
                                <change-password-modal @onUpdate="onUpdate" />
                                <change-email-modal @onUpdate="onUpdateRefetch" />
                                <change-heir-modal @onUpdate="onUpdateRefetch" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-user text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right">
                                        <div class="widget-heading fsize-1">{{ $t('username')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="font-weight-bolder fsize-1">{{ user.username }}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-mail text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right">
                                        <div class="widget-heading fsize-1">{{ $t('email')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="font-weight-bolder fsize-1">{{ user.email }}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-map-2 text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right mr-1">
                                        <div class="widget-heading fsize-1">{{ $t('country')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="language-icon profile-flag">
                                            <country-flag :country="user.country" class="opacity-8" size="small" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-medal text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right mr-1">
                                        <div class="widget-heading fsize-1">{{ $t('rank')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="font-weight-bolder fsize-1">{{ getRankName(user.totals.rank) }}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-date text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right mr-1">
                                        <div class="widget-heading fsize-1">{{ $t('member-since')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="font-weight-bolder fsize-1">{{ user.createdAt|formatDateShort  }}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" v-if="user.sponsor">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-user text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right mr-1">
                                        <div class="widget-heading fsize-1">{{ $t('sponsor')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="font-weight-bolder fsize-1">{{ user.sponsor.username }}</div>
                                        <div class="font-weight-bolder fsize-1" v-if="user.sponsor.email">{{ user.sponsor.email }}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-file text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right mr-1">
                                        <div class="widget-heading fsize-1">{{ $t('KYC')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="font-weight-bolder fsize-1 green--text" v-if="user.hasKYC">{{ $t('kyc-confirmed') }}</div>
                                        <div class="font-weight-bolder fsize-1 green--red" v-else>
                                            <div v-if="user.kycStatus">{{getKycStatus}} <a v-if="user.kycStatus.status !== 'pending'" href="/#/kyc">{{$t('kyc-submit-new')}}</a> <span v-if="user.kycStatus.message && user.kycStatus.status === 'denied'">{{user.kycStatus.message}}</span> </div>
                                            <div v-else>{{$t('kyc-unconfirmed')}} <a href="/#/kyc">{{$t('kyc-submit')}}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left center-elem mr-2 row-icon">
                                        <i class="pe-7s-users text-muted fsize-3"></i>
                                    </div>
                                    <div class="widget-content-left field-min-width text-right mr-1">
                                        <div class="widget-heading fsize-1">{{ $t('heir')}}:</div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="font-weight-bolder fsize-1">{{ user.heirContact  }}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
    </div>
        <v-snackbar
            v-model="successBox"
            :timeout="2000"
            color="primary"
            absolute
            right
            rounded="pill"
        >
            {{ $t('success')}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="successBox = false"
                >
                    {{ $t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
    import { library } from "@fortawesome/fontawesome-svg-core";
    import {
        faArrowRight,
        faArrowLeft,
        faAngleUp,
        faDotCircle,
        faAngleDown
    } from "@fortawesome/free-solid-svg-icons";
    import { mapGetters } from 'vuex'
    import ChangePasswordModal from "@/components/modals/ChangePasswordModal";
    import ChangeEmailModal from "@/components/modals/ChangeEmailModal";
    import ChangeHeirModal from "@/components/modals/ChangeHeirModal";
    import CountryFlag from "vue-country-flag";
    library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown);
    export default {
        components: {
            ChangeHeirModal,
            ChangeEmailModal,
            ChangePasswordModal,
            CountryFlag
        },
        props: ["user"],
        data: () => ({
            heading: "My profile",
            subheading:
                "",
            icon: "pe-7s-science icon-gradient bg-happy-itmeo",
            fill: { gradient: ["var(--primary)"] },
            fill1: { gradient: ["#2af598", "#009efd"] },
            fill2: { gradient: ["#fccb90", "#d57eeb"] },
            f1: { gradient: ["#3f6ad8"] },
            f2: { gradient: ["#3ac47d"] },
            f3: { gradient: ["#16aaff"] },
            f4: { gradient: ["#f7b924"] },
            f5: { gradient: ["#d92550"] },
            f6: { gradient: ["#444054"] },
            successBox: false,
        }),
        methods: {
            getRankName(val) {
                const rankName = [
                    this.$i18n.t('rank-0'),
                    this.$i18n.t('rank-1'),
                    this.$i18n.t('rank-2'),
                    this.$i18n.t('rank-3'),
                    this.$i18n.t('rank-4'),
                    this.$i18n.t('rank-5'),
                    this.$i18n.t('rank-6'),
                    this.$i18n.t('rank-7'),
                    this.$i18n.t('rank-8'),
                    this.$i18n.t('rank-9'),
                    this.$i18n.t('rank-10'),
                    this.$i18n.t('rank-11'),
                ];
                return rankName[val];
            },
            getRankImg(rank) {
                return require('../../assets/images/ranks/'+rank+'.png')
            },
            onUpdate() {
                this.successBox = true
            },
            onUpdateRefetch() {
                this.$emit('onUpdate')
            }
        },
        computed: {
            ...mapGetters(['profile.fullName', 'profile.rankImg']),
            kyc() {
                if (this.$store.state.profile.kyc === 0) return "unconfirmed";
                else if (this.$store.state.profile.kyc === 1) return "pending";
                else if (this.$store.state.profile.kyc === 2) return "confirmed";
                else return "rejected";
            },
            getKycStatus() {
                if (!this.user.kycStatus) return this.$i18n.t('kyc-unconfirmed')
                if (!this.user.kycStatus.status) return this.$i18n.t('kyc-unconfirmed')
                if (this.user.kycStatus.status === 'done') return this.$i18n.t('kyc-unconfirmed')
                if (this.user.kycStatus.status === 'pending') return this.$i18n.t('kyc-pending')
                if (this.user.kycStatus.status === 'rejected') return this.$i18n.t('kyc-rejected')
                return this.user.kycStatus.status
            },
        }
    };
</script>
<style scoped>
    .profile-flag {
        max-height: 1rem;
    }
    .confirmed {
        color: #0ba360;
    }
    .unconfirmed {
        color: red;
    }
    .pending {
        color: #1e3c72;
    }
    .widget-heading{
        display: flex;
    }
    .avatar-icon-profile {
        height: 150px!important;
        width: 150px!important;
    }
</style>