<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="amber darken-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="text-unset"
                >
                    {{ $t('change-password')}}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ $t('change-password')}}
                </v-card-title>

                <v-card-text>
                    <b-form>
                        <b-row>
                            <b-col md="12" >
                                <b-form-group
                                    :label="$t('enter-current-password')"
                                >
                                    <b-form-input id="password-0" type="password" name="password-0" :placeholder="$t('password')" v-model="form.currentPassword" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="12" >
                                <b-form-group
                                    :label="$t('enter-new-password')"
                                    :description="$t('password-strength')"
                                >
                                    <b-form-input id="password-1" type="password" name="password-1" :placeholder="$t('password')" v-model="form.password1" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="12" >
                                <b-form-group
                                    :label="$t('confirm-new-password')"
                                >
                                    <b-form-input id="password-2" type="password" name="password-2" :placeholder="$t('password-repeat')" v-model="form.password2" required v-validate="'required|confirmed:password1'" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="danger"
                        text
                        @click="dialog = false"
                        class="text-unset"
                        small
                    >
                        {{ $t('close')}}
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="resetPassword"
                        :disabled="form.password1 !== form.password2 || form.password1.length < 6"
                        class="text-unset"
                        small
                    >
                        {{ $t('change')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "ChangePasswordModal",
    data() {
        return {
            form: {
                currentPassword: '',
                password1: '',
                password2: ''
            },
            dialog: false,
            error: null
        }
    },
    methods: {
        resetPassword() {
            this.$apollo.mutate({
                mutation: gql`mutation($password:String!,$newPassword:String!){changePassword(password:$password,newPassword:$newPassword){error,user{id}}}`,
                variables: {
                    password: this.form.currentPassword,
                    newPassword: this.form.password1
                }
            }).then((resp)=> {
                if (!resp.data.error) {
                    this.dialog = false
                    this.$router.go(0)
                }
                else {
                    this.error = resp.data.error
                }
            })
        }
    }
}
</script>

<style scoped>

</style>