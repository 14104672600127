<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="amber darken-3"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="text-unset"
                >
                    {{ $t('change-heir')}}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ $t('change-heir')}}
                </v-card-title>

                <v-card-text>
                    <b-form>
                        <b-row>

                            <b-col md="12" >
                                <b-form-group
                                    :label="$t('enter-heir-name')"
                                >
                                    <b-form-input id="name" type="text" name="name" :placeholder="$t('heir-name')" v-model="form.name" required />
                                </b-form-group>
                            </b-col>

                            <b-col md="12" >
                                <b-form-group
                                    :label="$t('enter-heir-email')"
                                >
                                    <b-form-input id="email" type="email" name="email" :placeholder="$t('email')" v-model="form.email" required />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="danger"
                        text
                        @click="dialog = false"
                        class="text-unset"
                        small
                    >
                        {{ $t('close')}}
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="changeHeir"
                        :disabled="form.name.length < 6 && form.email.length < 6"
                        class="text-unset"
                        small
                    >
                        {{ $t('change')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "ChangeHeirModal",
    data() {
        return {
            form: {
                name: '',
                email: ''
            },
            dialog: false,
            error: null
        }
    },
    methods: {
        changeHeir() {
            this.$apollo.mutate({
                mutation: gql`mutation($name:String!,$contact:String!){appointHeir(name:$name,contact:$contact)}`,
                variables: {
                    name: this.form.name,
                    contact: this.form.email
                }
            }).then(() => this.$router.go(0))
        }
    }
}
</script>

<style scoped>

</style>